<template>
  <div class="col-md-12" v-if="Object.keys(admin_scenario_issue).length !== 0">
    <h5>Hub Details</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <b>Name: </b> {{ admin_scenario_issue.hub.name }}<br>
            <b>Address: </b> {{ admin_scenario_issue.hub.address }}<br>
            <b>Phone: </b> {{ admin_scenario_issue.hub.phone }}
          </div>
          <div class="col-md-6">
            <b>Hub Employees: </b> {{ admin_scenario_issue.hub.hub_employees }}<br>
            <b>Department Working: </b>
            <template v-for="(department, index) in admin_scenario_issue.hub.departments">
                <span class="item" :key="index">
                    {{ department.department_name }}
                      <span v-if="index != Object.keys(admin_scenario_issue.hub.departments).length - 1">, </span>
                </span>
            </template><br>
            <b>Email: </b> {{ admin_scenario_issue.hub.email }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'admin_scenario_issue_hub_detail',
  props: ['admin_scenario_issue'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.item:last-child .comma {
  display: none;
}
</style>
