<template>
  <div>
    <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
      <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
    </div>
    <div class="display-comment" v-for="(comment, index) in comments" :key="index">
      <div class="card" style="margin-bottom: 1rem !important;">
        <div class="card-body">
          <span v-if="comment.user.profile_picture != null">
              <img :src="comment.user.profile_picture_url" class="rounded" width="40px" height="40px" alt="Profile Picture">
          </span>
          <span v-else> <img src="/resources/images/avatars/avatar-2.png" class="rounded" alt="Profile Picture"></span>
          <strong class="ml-2">{{ comment.user.name }}</strong>
          <p style="margin-bottom: 0px !important;">{{ comment.comment }}</p>
          <p style="margin-top: 0px !important;">{{ commentAt(comment.created_at) }}</p>
          <a href="" id="reply"></a>
          <b-collapse :id="'reply_div_'+comment.id">
            <div class="form-group">
              <label for="users" class="col-form-label col-form-label-sm">Users[* For Notify]</label>
              <v-select v-model="selected_users" multiple  :name="'users_'+ index" :id="'users_' + index" label="user_data" :options="users"  placeholder="Select users"></v-select>
            </div>
            <div class="form-group">
              <input type="text" v-model="comment.reply_comment" :name="'comment_' + index" :id="'comment_' + index" class="form-control"/>
              <input type="hidden" v-model="admin_scenario_issue_id" :name="'admin_scenario_issue_id_' + index" :id="'admin_scenario_issue_id_' + index"/>
              <input type="hidden" v-model="comment.id" :name="'comment_id_' + index" :id="'comment_id_' + index"/>
            </div>
            <div class="form-group">
              <input type="submit" id="'submit_' + index" class="btn btn-sm btn-outline-danger py-0" style="font-size: 0.8em;" @click.prevent="replyComment(comment, index)" value="Reply"/>
            </div>
          </b-collapse>
          <a v-b-toggle :href="'#reply_div_'+comment.id" @click.prevent>Reply</a>
        </div>
      </div>
      <admin-scenario-comment-reply
        :admin_scenario_issue_id="admin_scenario_issue_id"
        :comments="comment.replies"
        :users="users"
        v-on="$listeners">
      </admin-scenario-comment-reply>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import adminScenarioCommentReply from '@/views/adminScenario/issue/partial/admin-scenario-comment-reply'
import moment from 'moment'

export default {
  name: 'admin-scenario-comment-reply',
  props: ['admin_scenario_issue_id', 'comments', 'users'],
  components: { adminScenarioCommentReply },
  data() {
    return {
      selected_users: [],
      validation_errors: {},
      loader: false,
      show: false,
      index: -1,
      comment_reply: false,
    }
  },
  mounted() {},
  methods: {
    replyComment(comment, index) {
      // eslint-disable-next-line no-prototype-builtins
      if (!comment.hasOwnProperty('reply_comment')) {
        comment.reply_comment = ''
      }
      this.loader = true
      const formData = new FormData()
      formData.append('admin_scenario_issue_id', this.admin_scenario_issue_id)
      formData.append('comment', comment.reply_comment)
      formData.append('comment_id', comment.id)
      formData.append('selected_users', JSON.stringify(this.selected_users))
      apiClient.post('api/admin-scenario/issue/comment/replyStore', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-type': 'application/json',
        },
      }).then(response => {
        this.loader = false
        this.selected_users = []
        if (response.data.error === false) {
          this.$notification.success({
            message: response.data.message,
          })
          this.$emit('getAdminScenarioIssue')
        } else {
          this.$notification.error({
            message: response.data.message,
          })
        }
      }).catch((error) => {
        console.log(error)
        this.loader = false
        this.validation_errors = error.response.data.errors
        this.show = true
        this.hide()
      })
    },
    commentAt(date) {
      return moment(date).format('LLL')
    },
    hideReplyDiv(index) {
      this.comment_reply = !this.comment_reply
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style scoped>
.display-comment .display-comment {
  margin-left: 40px
}
.rounded {
  border-radius: 1.25rem !important;
}
.v-text-field input::placeholder {
  color: green;
}
</style>
