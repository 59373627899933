<template>
    <div class="card" style="margin-left: 5px; margin-right: 5px;">
      <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
        <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
      </div>
      <div class="card-body">
        <h5>Display Comments</h5>
        <admin-scenario-comment-reply
          :admin_scenario_issue_id="admin_scenario_issue_id"
          :comments="comments"
          :users="users"
          v-on="$listeners">
        </admin-scenario-comment-reply>
        <hr/>
      </div>
      <div class="card-body">
        <h5>Leave a comment</h5>
        <form>
          <div class="form-group">
            <label for="users" class="col-form-label col-form-label-sm">Users[* For Notify]</label>
            <v-select v-model="selected_users" multiple  name="'users'" id="'users'" label="user_data" :options="users"  placeholder="Select users"></v-select>
          </div>
          <div class="form-group">
            <input type="text" v-model="start_comment"  v-validate="'required'" name="comment" class="form-control" />
            <input type="hidden" v-model="admin_scenario_issue_id" name="admin_scenario_issue_id" />
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-sm btn-outline-danger py-0" style="font-size: 0.8em;" @click.prevent="startComment" value="Add Comment" />
          </div>
        </form>
      </div>
    </div>
</template>

<script>
import apiClient from '@/services/axios'
import adminScenarioCommentReply from '@/views/adminScenario/issue/partial/admin-scenario-comment-reply'

export default {
  name: 'admin-scenario-comment',
  props: ['admin_scenario_issue_id', 'comments', 'users'],
  components: { adminScenarioCommentReply },
  data() {
    return {
      validation_errors: {},
      selected_users: [],
      loader: false,
      show: false,
      spinning: false,
      delayTime: 500,
      start_comment: '',
    }
  },
  mounted() {},
  methods: {
    // },
    startComment() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('admin_scenario_issue_id', this.admin_scenario_issue_id)
          formData.append('comment', this.start_comment)
          formData.append('selected_users', JSON.stringify(this.selected_users))
          apiClient.post('api/admin-scenario/issue/comment/store', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } }).then(response => {
            this.loader = false
            if (response.data.error === false) {
              this.$notification.success({
                message: response.data.message,
              })
              this.start_comment = ''
              this.selected_users = []
              this.$emit('getAdminScenarioIssue')
            } else {
              this.$notification.error({
                message: response.data.message,
              })
            }
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style scoped>
.display-comment .display-comment {
  margin-left: 40px
}
</style>
